<template>
  <div class="login">
    <div class="top-pic">
      <img src="@/assets/img/loginRegister/logo.png" alt="" />
    </div>
    <div class="holder">
      <div class="box">
        <div class="icon-input flex align-center">
          <img src="@/assets/img/loginRegister/phone.png" alt="" />
          <p>手机号<span>（注：1个微信只能绑定1个固定手机号）</span></p>
        </div>
        <input v-model.trim="phone" type="tel" placeholder="请输入手机号" maxlength="11"/>
      </div>
      <div class="box">
        <div class="icon-input flex align-center">
          <img src="@/assets/img/loginRegister/password.png" alt="" />
          <p>密码</p>
        </div>
        <div class="input-password flex flex-between align-center">
          <input
            v-model="password"
            :type="lookShow ? 'text' : 'password'"
            placeholder="请输入登录密码"
            maxlength="12"
          />
          <img
            v-show="!lookShow"
            src="@/assets/img/loginRegister/no-eye.png"
            alt=""
            @click="eyeFun"
          />
          <img
            v-show="lookShow"
            src="@/assets/img/loginRegister/eye.png"
            alt=""
            @click="eyeFun"
          />
        </div>
      </div>
      <div class="register-text flex flex-between">
        <p @click="getPassword">忘记密码？</p>
        <p>没有账号，<span @click="toRegister">立即注册</span></p>
      </div>
      <p
        :class="[
          'next-login',
          { 'next-sure': (this.phone || this.phone === 0) && (this.password || this.password === 0) },
        ]"
        @click="loginFun"
      >
        登录
      </p>
    </div>
  </div>
  <toast-box
    v-if="showToast"
    :show-toast="showToast"
    :msg="msg"
    @sure="handleSure"
  ></toast-box>
</template>
<script>
import ToastBox from "../../components/toast.vue";
import {
    loginIn
} from '@/utils/loginRegister.js';
// 0=游客,1=普通会员,2=VIP会员,3=达人,4=超级达人
export default {
  name: "Login",
  components: {
    ToastBox,
  },
  data() {
    return {
      lookShow: false,
      phone: "",
      password: "",
      showToast: false,
    };
  },
  methods: {
    getPassword() {
      this.$router.push("/forgetPassword");
    },
    eyeFun() {
      if (this.lookShow) {
        this.lookShow = false;
      } else {
        this.lookShow = true;
      }
    },
    toRegister() {
      this.$router.push("/register");
    },
    handleSure(val) {
      this.showToast = val;
    },
    loginFun() {
      if (!this.phone && this.phone !== 0) {
        return
      } else if (!/^1\d{10}$/.test(this.phone)) {
        this.showToast = true;
        this.msg = "手机号填写错误<br>请填写11位正确的手机号";
        return
      } else if (!this.password && this.password !== 0) {
        return
      }
      let data = {
          username: this.phone,
          password: this.password,
          memberId: this.$cookies.get('memberId')
      };
      loginIn(data).then(res => {
        // 登录接口进行处理过，res报错会返回值
        if (res.errorCode == "0000") {
          this.$toast("登录成功")
          this.$cookies.set('isLogin', 'true', '1d');
          this.$cookies.set('userId', res.id, '1d');
          this.$cookies.set('userType', res.userType, '1d');
          if(res.userType) {
            if (res.userType == 7) {
              let hostName = this.$route.query.redirect; // 获取域名
              if (hostName && hostName === location.hostname) {
                // 判断如果域名是你项目域名，说明是从本网站内部跳转过来的，
                this.$router.go(-1); // 登录成功后，返回上次进入的页面；
              } else {
                this.$router.push("/");
              }
            } else if (res.userType == 9) {
              this.$router.push("/businessOverview");
            } else {
              this.$toast.fail("登录失败，参数userType" + res.userType +"未知")
            }
          } else {
            this.$toast.fail("登录失败，参数userType未返回")
          }
        } else if (res.errorCode == "9902") {
          this.msg = "登录用户不存在<br>请注册成功后再操作登录"
          this.showToast = true;
        } else if (res.errorCode == "9901") {
          this.msg = "输入的登录密码错误<br>请重新输入"
          this.showToast = true;
        } else if (res.errorCode == "9903") {
          this.msg = "账户异常<br>请联系灵龙客服作具体了解"
          this.showToast = true;
        } else {
          this.$toast(res.errorMsg)
        }
      })
    },
  },
};
</script>
<style lang="scss" scoped>
.login {
  width: 100vw;
  height: 100vh;
  background-color: #ffffff;
  overflow-x: hidden;
  overflow-y: scroll;
  .top-pic {
    height: 161px;
    text-align: center;
    img {
      width: 120px;
      height: 120px;
      margin-top: 35px;
    }
  }
  .holder {
    .box {
      width: 315px;
      border-bottom: solid 1px #f6f6f6;
      padding: 30px 0 15px;
      margin: 0 auto;
      text-align: left;
      position: relative;
      .icon-input {
        height: 18px;
        img {
          width: 16px;
          height: 16px;
        }
        p {
          font-size: 13px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          margin-left: 5px;
          span{
            color: #999999;
            font-size: 12px;
          }
        }
      }
      .code {
        position: absolute;
        bottom: 14px;
        right: 0;
        width: 84px;
        height: 28px;
        -webkit-border-radius: 14px;
        -moz-border-radius: 14px;
        -ms-border-radius: 14px;
        -o-border-radius: 14px;
        border-radius: 14px;
        border: 1px solid #e62129;
        font-size: 11px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #e62129;
        line-height: 28px;
        text-align: center;
      }
      .count {
        position: absolute;
        bottom: 14px;
        right: 0;
        width: 84px;
        height: 28px;
        -webkit-border-radius: 14px;
        -moz-border-radius: 14px;
        -ms-border-radius: 14px;
        -o-border-radius: 14px;
        border-radius: 14px;
        border: 1px solid #cccccc;
        font-size: 11px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        text-align: center;
        line-height: 28px;
      }
    }
    input {
      height: 24px;
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 24px;
      margin-top: 15px;
    }
    input::-webkit-input-placeholder {
      color: #cccccc;
    }
    .register-text {
      width: 315px;
      margin: 15px auto 30px;
      p {
        height: 18px;
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 18px;
        span {
          color: #0074d7;
        }
      }
    }
    .next-login {
      width: 315px;
      height: 44px;
      background: #e62129;
      -webkit-border-radius: 22px;
      -moz-border-radius: 22px;
      -ms-border-radius: 22px;
      -o-border-radius: 22px;
      border-radius: 22px;
      opacity: 0.5;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      text-align: center;
      line-height: 44px;
      margin: 0 auto;
    }
    .next-sure {
      opacity: 1;
    }
  }
  .input-password {
    height: 24px;
    margin-top: 15px;
    input {
      margin-top: 0 !important;
    }
    img {
      width: 16px;
      height: 16px;
    }
  }
}
</style>